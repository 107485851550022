<template>
  <div>
    <div
      v-if="!viewingItemDetails"
      id="idItemList"
    >
      <b-card
        no-body
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-table
              id="itemsTable"
              ref="refItemTable"
              thead-class="itemTableHeader"
              primary-key="id"
              responsive
              show-empty
              striped
              sticky-header="380px"
              no-border-collapse
              empty-text="Não há itens cadastrados."
              :items="items"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :tbody-tr-class="rowClass"
              @row-clicked="showItemDetail"
            >
              <template #table-busy>
                <div class="text-center my-3">
                  <b-spinner
                    variant="info"
                    class="align-middle mb-1"
                  />
                  <br>
                  <strong class="pt-2">Carregando...</strong>
                </div>
              </template>
              <template #cell(position)="field">
                <b-media vertical-align="center">
                  <span
                    class="d-flex align-items-center font-weight-bold"
                  >
                    <template v-if="field.item.position">
                      <span>{{ field.item.position }}</span>
                      <span
                        v-if="field.item.position != '-'"
                        class="ml-25"
                      >
                        º
                      </span>
                    </template>
                    <template v-else>
                      -
                    </template>
                    <svg-icon
                      v-if="field.item.position === 1"
                      type="mdi"
                      size="25"
                      class="ml-50 colorIcon"
                      :path="mdiTrophyOutline"
                    />
                  </span>
                </b-media>
              </template>

              <template #cell(number)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.number }}
                  </span>
                </b-media>
              </template>

              <template #cell(title)="field">
                <b-media vertical-align="center">
                  <span
                    v-b-tooltip.hover.right
                    class="font-weight-bold d-block text-nowrap"
                    :title="`DESCRIÇÃO: ${getItemTitle(field.item)}`"
                  >
                    {{ getItemTitle(field.item) | truncate(20, '...') }}
                  </span>
                </b-media>
              </template>

              <template #cell(quantity)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <template v-if="field.item.items.length === 1">
                      {{ field.item.items[0].quantity ? field.item.items[0].quantity : '-' }}
                    </template>
                    <template v-else>
                      {{ field.item.quantity ? field.item.quantity : '-' }}
                    </template>
                  </span>
                </b-media>
              </template>

              <template #cell(unitary_value)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <template v-if="field.item.items.length === 1">
                      R$ {{ field.item.items[0].value ? field.item.items[0].value.toString().replace('R$', '') : '' }}
                    </template>
                    <template v-else>
                      R$ {{ field.item.value ? field.item.value.toString().replace('R$', '') : '' }}
                    </template>
                  </span>
                </b-media>
              </template>

              <template #cell(total_value)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <template v-if="field.item.items.length === 1">
                      R$ {{ getTotalValue(field.item) | formatCurrency() }}
                    </template>
                    <template v-else>
                      R$ {{ field.item.value ? field.item.value.toString().replace('R$', '') : '' }}
                    </template>
                  </span>
                </b-media>
              </template>

              <template #cell(status)="field">
                <div
                  class="d-block text-nowrap"
                >
                  <b-badge
                    pill
                    class="text-capitalize sizeMin px-1 d-inline-flex align-items-center justify-content-center"
                    :style="{
                      backgroundColor: '#005C96',
                      width: '150px',
                    }"
                  >
                    {{ field.item.status }}
                  </b-badge>
                </div>
              </template>

              <template #cell(is_homologated)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <template v-if="field.item.is_homologated">
                      {{ field.item.is_homologated }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </span>
                </b-media>
              </template>

              <template #cell(is_me_exclusive)="field">
                <div
                  class="d-block text-nowrap"
                >
                  <span>
                    {{ field.item.is_me_exclusive ? 'Sim' : 'Não' }}
                  </span>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-else>
      <b-card
        no-body
        class="cardItemDetailTitle mb-1"
      >
        <b-row>
          <div class="d-flex flex-column justify-content-center align-items-center session-1 ml-1">
            <span class="rankingTitle mb-50">
              Posição no Ranking
            </span>
            <div class="rankingPosition">
              <span>
                {{ currentItem.position }}
              </span>
              <span v-if="currentItem.position != '-'">
                º
              </span>
            </div>
          </div>
          <b-col
            class="d-flex align-items-center session-2"
          >
            <b-row class="w-100">
              <b-col
                cols="12"
                md="10"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <span
                      v-if="currentItem.items.length <= 1"
                      v-b-tooltip.hover.right
                      :title="getItemTitle(currentItem)"
                      class="itemName"
                    >
                      {{ currentItem.number }} - {{ getItemTitle(currentItem) | truncate(50, '...') }}
                    </span>
                    <span
                      v-else
                      v-b-tooltip.hover.right
                      :title="getItemTitle(currentItem)"
                      class="itemName"
                    >
                      Lote {{ getItemTitle(currentItem) | truncate(50, '...') }}
                    </span>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <span>
                      Quantidade solicitada:
                      <strong>
                        {{ currentItem.items.length === 1 ? (currentItem.items[0].quantity || '-' ) : (currentItem.quantity || '-') }}
                      </strong>
                    </span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <span class="ItemSituation">{{ currentItem.status || '-' }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <span>
                      Valor estimado (unitário):
                      <strong>
                        R$ {{
                          currentItem.items.length === 1 ?
                            currentItem.items[0].value.toString().replace('R$', '')
                            : currentItem.value.toString().replace('R$', '')
                        }}
                      </strong>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                md="2"
                class="d-flex justify-content-end align-items-center"
              >
                <div>
                  <b-button
                    variant="outline-primary"
                    pill
                    class="btn-next-back p-1"
                    :disabled="currentIndex === 0"
                    @click="nextBackItem('back')"
                  >
                    <svg-icon
                      type="mdi"
                      size="25"
                      class="colorArrowIcon"
                      :path="mdiChevronLeft"
                    />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    pill
                    class="btn-next-back p-1"
                    :disabled="currentIndex === items.length -1"
                    @click="nextBackItem('next')"
                  >
                    <svg-icon
                      type="mdi"
                      size="25"
                      class="colorArrowIcon"
                      :path="mdiChevronRight"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="currentItem.items.length > 1"
        no-body
        class="mb-1"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <div class="p-2">
              <span>
                <strong>Produtos</strong>
              </span>
            </div>
          </b-col>
          <b-col>
            <b-table
              id="itemProductsTable"
              thead-class="itemTableHeader"
              tbody-tr-class="itemTableRow"
              responsive
              striped
              :items="currentItem.items"
              :fields="productColumns"
            >
              <template #cell(description)="field">
                <b-media
                  v-b-tooltip.hover.right
                  :title="field.item.description"
                  vertical-align="center"
                >
                  <span
                    class="d-block text-nowrap"
                  >
                    {{ field.item.description | truncate(35, '...') }}
                  </span>
                </b-media>
              </template>
              <template #cell(unit)="field">
                <b-media vertical-align="center">
                  <span
                    class="d-block text-nowrap"
                  >
                    {{ field.item.unit || '-' }}
                  </span>
                </b-media>
              </template>
              <template #cell(value)="field">
                <b-media vertical-align="center">
                  <span
                    class="d-block text-nowrap"
                  >
                    {{ field.item.value ? `R$ ${field.item.value}` : '-' }}
                  </span>
                </b-media>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        no-body
        class="mb-1"
      >
        <b-row>
          <b-col>
            <b-tabs
              class="itemDetailTab"
              card
            >
              <b-tab
                class="p-0"
                :active="activeTab === dataTab.invoicing"
                @click="selectedTab(dataTab.invoicing)"
              >
                <template #title>
                  <b-card-title>
                    <span class="d-inline">Todas as Propostas</span>
                  </b-card-title>
                </template>
                <b-card-body
                  class="p-0 pb-2"
                >
                  <div
                    v-if="currentItem.grid.length === 0"
                    class="d-flex justify-content-start align-items-center p-3"
                  >
                    <svg-icon
                      type="mdi"
                      size="42"
                      class="colorArrowIcon"
                      :path="mdiArchiveAlert"
                    />
                    <h4 class="m-0 ml-1">
                      Sem propostas para o item atual
                    </h4>
                  </div>
                  <div
                    v-for="(companyBid, index) in currentItem.grid"
                    :key="index"
                    v-b-toggle="`collapse-${index}`"
                    class="company-row px-3 py-2"
                    @click="collapseRow(index)"
                  >
                    <b-row>
                      <b-col
                        cols="1"
                      >
                        <div
                          class="d-flex justify-content-start fw-500 positionGridLabel"
                        >
                          {{ index + 1 }} º
                        </div>
                      </b-col>
                      <b-col
                        cols="6"
                      >
                        <div
                          class="d-flex justify-content-start fw-500"
                        >
                          {{ companyBid.name }}
                        </div>
                        <small>
                          <span v-if="companyBid.is_me">
                            <strong>ME/EPP</strong>
                          </span>
                        </small>
                      </b-col>
                      <b-col
                        cols="4"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center flex-column h-100"
                        >
                          <span class="gridLabel">
                            Valor ofertado (unitário | total)
                          </span>
                          <span>
                            R$ {{ companyBid.bid.replace('R$', '') }} |
                            R$ {{ getBidTotalOffer(companyBid.bid) | formatCurrency }}
                          </span>
                        </div>
                      </b-col>
                      <b-col
                        cols="1"
                      >
                        <div class="d-flex justify-content-end align-items-center h-100">
                          <svg-icon
                            type="mdi"
                            size="25"
                            class="colorArrowIcon"
                            :path="mdiChevronRight"
                            :style="{
                              transform: (collapsedRows.includes(index)) ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'all 0.3s ease'
                            }"
                          />
                        </div>
                      </b-col>
                    </b-row>
                    <b-collapse
                      :id="`collapse-${index}`"
                      class="mt-2 pb-2"
                    >
                      <div>
                        <b-row class="mt-3">
                          <b-col
                            cols="4"
                          >
                            <div
                              class="d-flex justify-content-center align-items-start flex-column h-100"
                            >
                              <span class="gridLabel">
                                Marca/Fabricante
                              </span>
                              <span>
                                <template v-if="('supplier' in companyBid && companyBid.supplier)">
                                  {{ companyBid.supplier }}
                                </template>
                                <template v-else>
                                  -
                                </template>
                              </span>
                            </div>
                          </b-col>
                          <b-col
                            cols="4"
                          >
                            <div
                              class="d-flex justify-content-center align-items-start flex-column h-100"
                            >
                              <span class="gridLabel">
                                Modelo/Versão
                              </span>
                              <span>
                                <template v-if="('model' in companyBid && companyBid.model)">
                                  {{ companyBid.model }}
                                </template>
                                <template v-else>
                                  -
                                </template>
                              </span>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col cols="12">
                            <div
                              class="d-flex justify-content-center align-items-start flex-column h-100"
                            >
                              <span class="gridLabel">
                                Descrição detalhada
                              </span>
                              <span>
                                <template v-if="('description' in companyBid && companyBid.description)">
                                  {{ companyBid.description }}
                                </template>
                                <template v-else>
                                  -
                                </template>
                              </span>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-collapse>
                  </div>
                </b-card-body>
              </b-tab>
              <b-tab
                :active="activeTab === dataTab.commitment"
                class="p-0"
                @click="selectedTab(dataTab.commitment)"
              >
                <template #title>
                  <b-card-title>
                    <span class="d-inline">Minha Proposta</span>
                  </b-card-title>
                </template>
                <b-card-body
                  class="p-0"
                >
                  <template v-if="currentProposal">
                    <div>
                      <div class="p-3">
                        <b-row>
                          <b-col
                            cols="2"
                          >
                            <div
                              class="d-flex justify-content-start h-100"
                            >
                              <span>
                                <strong>CNPJ: {{ formatCNPJ(company.cnpj) }}</strong>
                              </span>
                            </div>
                          </b-col>
                          <b-col
                            cols="6"
                          >
                            <div
                              class="d-flex justify-content-start h-100 fw-500"
                            >
                              {{ company.corporate_name }}
                            </div>
                          </b-col>
                          <b-col
                            cols="3"
                          >
                            <div
                              class="d-flex flex-column justify-content-center align-items-start h-100"
                            >
                              <span class="gridLabel">
                                Valor ofertado (unitário)
                              </span>
                              <span>
                                R$ {{ currentProposal.price | formatCurrency() }}
                              </span>

                            </div>
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col
                            cols="3"
                          >
                            <div
                              v-b-tooltip.hover
                              class="d-flex justify-content-center align-items-start flex-column h-100"
                              :title="currentProposal.supplier"
                            >
                              <span class="gridLabel">
                                Marca/Fabricante
                              </span>
                              <span>
                                {{ currentProposal.supplier | truncate(20, '...') }}
                              </span>
                            </div>
                          </b-col>
                          <b-col
                            cols="3"
                          >
                            <div
                              class="d-flex justify-content-center align-items-start flex-column h-100"
                            >
                              <span class="gridLabel">
                                Modelo/Versão
                              </span>
                              <span>
                                {{ currentProposal.model }}
                              </span>
                            </div>
                          </b-col>
                          <!--
                          <b-col
                            cols="3"
                          >
                            <div
                              class="d-flex justify-content-center align-items-start flex-column h-100"
                            >
                              <span class="gridLabel">
                                Margem Estimada
                              </span>
                              <span>
                                {{ currentProposal.margin }} %
                              </span>
                            </div>
                          </b-col>
                          -->
                        </b-row>
                        <b-row class="mt-3">
                          <b-col cols="12">
                            <div
                              class="d-flex justify-content-center align-items-start flex-column h-100"
                            >
                              <span class="gridLabel">
                                Descrição no cadastro de produtos
                              </span>
                              <span>
                                {{ currentProposal.description }}
                              </span>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <b-table
                        id="proposalProductsTable"
                        thead-class="itemTableHeader"
                        tbody-tr-class="itemTableRow"
                        responsive
                        striped
                        :items="currentProposal.products"
                        :fields="proposalProductsColumns"
                      >
                        <template #cell(product_set)="field">
                          <b-media
                            v-b-tooltip.hover.right
                            :title="field.item.product_set.name || field.item.product_set.description"
                            vertical-align="center"
                          >
                            <span
                              class="d-block text-nowrap"
                            >
                              {{ (field.item.product_set.name || field.item.product_set.description) | truncate(35, '...') }}
                            </span>
                          </b-media>
                        </template>

                        <template #cell(price)="field">
                          <b-media vertical-align="center">
                            <span
                              class="d-block text-nowrap"
                            >
                              R$ {{ field.item.price }}
                            </span>
                          </b-media>
                        </template>
                      </b-table>
                    </div>
                  </template>

                  <template v-else>
                    <div class="d-flex justify-content-start align-items-center p-3">
                      <svg-icon
                        type="mdi"
                        size="42"
                        class="colorArrowIcon"
                        :path="mdiArchiveAlert"
                      />
                      <h4 class="m-0 ml-1">
                        Sem proposta para o item atual
                      </h4>
                    </div>
                  </template>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
      <b-row class="mb-2">
        <b-col
          class="d-flex justify-content-end"
        >
          <b-button
            id="toBackButton"
            variant="outline-primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="showItemDetail()"
          >
            Voltar
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCollapse,
  BCardBody,
  BTable,
  BMedia,
  VBTooltip,
  VBToggle,
  BSpinner,
  BBadge,
  BButton,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import {
  mdiTrophyOutline,
  mdiChevronRight,
  mdiChevronLeft,
  mdiArchiveAlert,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import SvgIcon from '@jamescoyle/vue-icon'
import formValidation from '@core/comp-functions/forms/form-validation'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCollapse,
    BTable,
    BMedia,
    BSpinner,
    BBadge,
    BButton,
    BTabs,
    BTab,
    SvgIcon,
  },
  filters: {
    formatCurrency,
    truncate,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    company: {
      type: [String, Object],
      required: true,
    },
    biddingItems: {
      type: Array,
      required: true,
    },
    itemsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    const viewingItemDetails = false

    const dataTab = {
      commitment: 1,
      delivery: 2,
      attach: 3,
      history: 4,
      invoicing: 5,
    }

    const activeTab = dataTab.invoicing

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'position',
        label: 'Posição',
        thStyle: { width: '5%' },
      },
      {
        key: 'number',
        label: 'Nº Item',
        thStyle: { width: '5%' },
      },
      {
        key: 'title',
        label: 'Nome do Produto',
        thStyle: { width: '10%' },
      },
      {
        key: 'quantity',
        label: 'Quantidade',
        thStyle: { width: '5%' },
      },
      {
        key: 'unitary_value',
        label: 'Valor Unitário Estimado',
        thStyle: { width: '10%' },
      },
      {
        key: 'total_value',
        label: 'Valor Total Estimado',
        thStyle: { width: '10%' },
      },
      {
        key: 'status',
        label: 'Status',
        thStyle: { width: '15%' },
      },
      {
        key: 'is_homologated',
        label: 'Situação',
        thStyle: { width: '15%' },
      },
      {
        key: 'is_me_exclusive',
        label: 'Exclusivo ME/EPP',
        thStyle: { width: '10%' },
      },
    ]
    const productColumns = [
      {
        key: 'number',
        label: 'Número',
      },
      {
        key: 'description',
        label: 'Descrição',
      },
      {
        key: 'quantity',
        label: 'Quantidade',
      },
      {
        key: 'unit',
        label: 'Unidade',
      },
      {
        key: 'value',
        label: 'Valor Unitário',
      },
    ]
    const proposalProductsColumns = [
      {
        key: 'product_set',
        label: 'Produto no cadastro Syslic',
      },
      {
        key: 'quantity',
        label: 'Quantidade',
      },
      {
        key: 'price',
        label: 'Custo do item',
      },
    ]

    const items = []

    const currentItem = {}
    const currentIndex = 0
    const collapsedRows = []
    const currentProposal = null

    return {
      dataTab,
      activeTab,
      refFormObserver,
      getValidationState,
      viewingItemDetails,
      sortBy,
      tableColumns,
      productColumns,
      isSortDirDesc,
      items,
      currentItem,
      currentIndex,
      collapsedRows,
      currentProposal,
      proposalProductsColumns,
    }
  },
  watch: {
    itemsList: {
      handler() {
        if (this.itemsList) {
          this.items = JSON.parse(JSON.stringify(this.itemsList))
        }
      },
      immediate: true,
    },
    currentItem: {
      handler() {
        this.currentProposal = null

        this.biddingItems.forEach(item => {
          if (this.currentItem) {
            if (Number(item.number) === Number(this.currentItem.number)) {
              const data = {
                supplier: '-',
                model: '-',
                description: item.description,
                quantity: item.quantity,
                price: parseFloat(item.price),
                margin: item.margin_min,
                total_price: parseFloat(item.price) * Number(item.quantity),
                products: item.product_list,
              }

              if (item.product_list.length === 1) {
                data.supplier = item.product_list[0].product_set.supplier ? item.product_list[0].product_set.supplier_set.name : '-'
                data.model = item.product_list[0].product_set.model ? item.product_list[0].product_set.model : '-'
                data.description = item.product_list[0].product_set.description ? item.product_list[0].product_set.description : item.description
              }

              this.currentProposal = data
            }
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    collapseRow(index) {
      if (this.collapsedRows.includes(index)) {
        this.collapsedRows = this.collapsedRows.filter(item => item !== index)
        return
      }

      this.collapsedRows.push(index)
    },
    rowClass(item) {
      if (item) {
        if ('position' in item && item.position !== '-') {
          return 'itemTableRowMyCompany'
        }
      }

      return 'itemTableRow'
    },
    getItemTitle(item) {
      if (item.items.length === 1) {
        return item.items[0].description
      }

      return item.title
    },
    formatCNPJ(cnpj) {
      const filteredCNPJ = cnpj.replace(/\D/g, '')

      if (filteredCNPJ.length !== 14) {
        return filteredCNPJ
      }

      return filteredCNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
    },
    getBidTotalOffer(bid) {
      const bidValue = parseFloat(
        bid.replace('R$', '').replace(/\./g, '').replace(',', '.').trim(),
      )

      if (this.currentItem.items.length === 1) {
        const quantity = parseFloat(this.currentItem.items[0].quantity)

        const result = parseFloat(bidValue * quantity).toFixed(2)

        return result
      }

      const quantity = this.currentItem.quantity ? this.currentItem.quantity : 1

      const result = parseFloat(bidValue * quantity).toFixed(2)

      return result
    },
    getTotalValue(item) {
      const itemData = JSON.parse(JSON.stringify(item.items[0]))

      const quantity = parseFloat(itemData.quantity)
      const value = parseFloat(itemData.value.replace('R$', '').replace(/\./g, '').replace(',', '.').trim())

      if (quantity > 1) {
        const result = parseFloat(quantity * value).toFixed(2)

        return result
      }

      return value
    },
    /* eslint-enable */
    showItemDetail(item, index) {
      this.currentItem = item
      this.currentIndex = index
      this.viewingItemDetails = !this.viewingItemDetails
    },
    nextBackItem(action) {
      if (action === 'next') {
        this.currentIndex += 1
        this.currentItem = this.items[this.currentIndex]
      } else {
        this.currentIndex -= 1
        this.currentItem = this.items[this.currentIndex]
      }
    },
    selectedTab(value) {
      this.activeTab = value
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrophyOutline,
      mdiChevronRight,
      mdiChevronLeft,
      mdiArchiveAlert,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idItemList .itemTableHeader th {
  color: $primary;
  font-weight: 600;
  font-size: 0.9rem;
  background-color: #E3EDF3;
}

#idItemList .itemListHeader {
  background-color: #F6F6F9
}

#idItemList .itemTableRow {
  cursor: default;
}

#idItemList .itemTableRow:hover {
  cursor: pointer;
  background: #798f9e1f;
}

#idItemList .itemTableRow:active {
  cursor: pointer;
  background: #3a454d1f;
}

#idItemList .itemTableRowMyCompany {
  cursor: default;
  border: 2px dashed #0370B5;
  background: rgba(2, 139, 224, 0.111);
}

#idItemList .itemTableRowMyCompany:hover {
  cursor: pointer;
  background: #798f9e1f;
}

#idItemList .itemTableRowMyCompany:active {
  cursor: pointer;
  background: #3a454d1f;
}

#idItemList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idItemList .headerContent {
  color: #464646;
}

#idItemList .itemIconType {
  color: $primary;
}

#btn-link-bidding {
  border: none !important;
}

.colorIcon {
  color: #049F0A;
}

#toBackButton {
  min-width: 116px;
}

.cardItemDetailTitle {
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.cardItemDetailTitle .rankingTitle {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
}

.cardItemDetailTitle .rankingPosition {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 72.414% */
}

.session-1 {
  width: 173px;
  height: 95px;
  flex-shrink: 0;

  border-radius: 15px 0px 0px 15px;
  background: #005C96;
  color: #fff;
}

.itemName {
  color: #005C96;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
}

.ItemSituation {
  color: #F00;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
}

.itemDetailTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0;
}

.itemDetailTab .card-header-tabs {
  margin-bottom: 0;
}

.itemDetailTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.itemDetailTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.itemDetailTab .nav-link.active {
  background-color: #e2edf3;
}

.itemDetailTab .nav-link.active h4 {
  color: $primary;
}

.itemDetailTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.colorArrowIcon {
  color: #005C96;
}

.btn-next-back {
  border: none !important;
}

.company-row {
  transition: all 0.3s ease;
  border-bottom: 1px solid #e9ecef;

  &:hover {
    background-color: #F6F6F9;
  }
}
</style>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

.fw-500 {
  font-weight: 500 !important;
}

* {
  ::-webkit-scrollbar {
    width: 10px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: $primary !important;
    border-radius: 10px !important;
  }
}
.positionGridLabel {
  color: $primary;
  font-size: 1.25rem;
}

.gridLabel {
  color: $primary;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9rem;
}
</style>
