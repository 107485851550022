<template>
  <div id="editPage">
    <div v-show="!isLoaded">
      <SpinLoader />
    </div>
    <div v-show="isLoaded">
      <messager
        :module="'bidding'"
        :thread="biddingId"
        :header="bidding.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <BRow
        class="justify-content-center mb-1"
      >
        <BCol
          cols="12"
          md="11"
        >
          <div>
            <BRow v-if="isLoaded">
              <BCol
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0 mb-1"
              >
                <BDropdown
                  id="actions"
                  variant="primary"
                  no-carret
                  right
                  class="dropdown-icon-wrapper ml-50"
                >
                  <template #button-content>
                    <FeatherIcon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <BDropdownItem
                    v-if="bidding.items_set.length > 0"
                    id="idExportBtn"
                    @click="showModaEffect()"
                  >
                    <SvgIcon
                      type="mdi"
                      size="100"
                      :style="{'height': '24px', 'width': '23px', 'margin-left': '-3px'}"
                      :path="mdiFileExportOutline"
                    />
                    <span
                      class="align-middle"
                      :style="{'margin-left': '15px'}"
                    >
                      Exportar - Effecti
                    </span>
                  </BDropdownItem>

                  <BDropdownItem
                    id="idClassificationGridBtn"
                    :to="{ name: 'bidding-edit', params: { id: biddingId } } "
                  >
                    <SvgIcon
                      type="mdi"
                      size="100"
                      :style="{'height': '24px', 'width': '23px', 'margin-left': '-3px'}"
                      :path="mdiClipboardEditOutline"
                    />
                    <span
                      class="align-middle"
                      :style="{'margin-left': '15px'}"
                    >
                      Licitação
                    </span>
                  </BDropdownItem>

                  <BDropdownItem
                    v-if="!bidding.is_homologated"
                    id="idHomologationBtn"
                    :disabled="!hasPermissionToUpdate"
                    @click="showModalHomologation()"
                  >
                    <SvgIcon
                      type="mdi"
                      size="100"
                      :style="{'height': '24px', 'width': '23px', 'margin-left': '-3px'}"
                      :path="mdiFileCertificateOutline"
                    />
                    <span
                      class="align-middle"
                      :style="{'margin-left': '15px'}"
                    >
                      Homologar
                    </span>
                  </BDropdownItem>

                  <BDropdownItem
                    id="idRemoveBtn"
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmDeleteBidModal()"
                  >
                    <SvgIcon
                      type="mdi"
                      size="18"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </BDropdownItem>
                </BDropdown>
              </BCol>
            </BRow>
          </div>
        </BCol>
      </BRow>
      <BRow class="justify-content-center">
        <BCol
          cols="12"
          md="11"
        >
          <BRow v-if="isLoaded">
            <BCol>
              <BCard
                no-body
                class="d-flex align-items-stretch margin-bottom-1"
              >
                <BCardTitle
                  class="pl-1 m-0"
                >
                  <BRow
                    align-v="center"
                    class="d-flex align-items-stretch"
                  >
                    <BCol
                      cols="12"
                      sm="8"
                    >
                      <BRow
                        class="mt-2 ml-50"
                      >
                        <BCol>
                          <BRow>
                            <BCol>
                              <span id="biddingHeaderTitle">{{ bidding.client_set.name }}</span>
                            </BCol>
                          </BRow>
                        </BCol>
                      </BRow>
                      <BRow class="mt-50 mb-1 ml-50">
                        <BCol>
                          <b-link
                            v-if="bidding.link_trade"
                            :href="bidding.link_trade"
                          >
                            <small>
                              <feather-icon
                                icon="Link2Icon"
                                size="18"
                                class="align-middle mr-50"
                              />
                              <span id="biddingLinkTrade">{{ bidding.link_trade | truncate(40, '...') }}</span>
                            </small>
                          </b-link>
                        </BCol>
                      </BRow>
                    </BCol>
                    <BCol
                      cols="12"
                      sm="4"
                      class="d-flex align-items-stretch justify-content-end text-right"
                    >
                      <div
                        v-if="bidding.is_homologated"
                        class="d-flex justify-content-start"
                      >
                        <BButton
                          id="btnUndoHomologation"
                          class="d-flex flex-column align-items-center btn-icon px-1"
                          variant="outline-light"
                          size="sm"
                          :disabled="!hasPermissionToUpdate"
                          @click="showModalUndoHomologation()"
                        >
                          <SvgIcon
                            id="icon-btn-homologated"
                            class="m-50"
                            type="mdi"
                            size="40"
                            :path="mdiFileCertificateOutline"
                          />
                          <span class="d-inline mb-50">Licitação</span>
                          <span class="d-inline mb-50">Homologada</span>
                        </BButton>
                      </div>
                    </BCol>
                  </BRow>
                </BCardTitle>
              </BCard>
              <BCard
                class="pt-0 pl-0 pr-0 ml-0 mr-0 margin-bottom-1"
              >
                <BRow>
                  <BCol
                    cols="12"
                    md="6"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <BRow
                      class="mb-50"
                    >
                      <BCol
                        class="d-flex align-items-center titleCard"
                      >
                        <SvgIcon
                          class="mr-50"
                          type="mdi"
                          size="30"
                          :path="mdiPodium"
                        />
                        <span
                          class="biddingHeaderSectionTitle"
                        >
                          CENTRAL DE CONTROLE DE LICITAÇÃO
                        </span>
                      </BCol>
                    </BRow>
                    <BRow>
                      <BCol>
                        <span>
                          {{ bidding.modality_set.name }} N° {{ bidding.trade_number }}
                        </span>
                      </BCol>
                    </BRow>
                    <BRow>
                      <BCol>
                        <span
                          v-if="bidding.uasg"
                          class="uasgTitle"
                        >
                          UASG/OC: {{ bidding.uasg }}
                        </span>
                      </BCol>
                    </BRow>
                    <BRow>
                      <BCol>
                        <span id="lastUpdate">
                          (Última atualização {{ grid.query_date }})
                        </span>
                      </BCol>
                    </BRow>
                  </BCol>
                </BRow>
              </BCard>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <BiddingItems
                v-if="isLoaded"
                :items-list="grid.items"
                :bidding-items="bidding.items_set"
                :company="bidding.company_set"
              />
            </BCol>
          </BRow>
        </BCol>
      </BRow>
    </div>
    <BModal
      id="idModalEffect"
      ref="modalEffect"
      no-close-on-backdrop
      no-close-on-esc
      centered
      cancel-variant="outline-primary"
      @ok="exportProductsCSV()"
    >
      <template #modal-cancel>
        Cancelar
      </template>
      <template #modal-ok>
        Exportar
      </template>
      <BForm
        class="mt-1"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1"
            >
              <SvgIcon
                class="colorIconAlert"
                type="mdi"
                size="70"
                :path="mdiCommentQuestionOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 fixLayoutTextModal"
            >
              Deseja exportar os dados para o modelo Effecti?
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalEffectSpinner"
      ref="modalEffectSpinner"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1"
            >
              <SvgIcon
                :style="{'color': '#FFD15C'}"
                type="mdi"
                size="70"
                :path="mdiFolderOpenOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 fixLayoutTextModal"
            >
              Exportando Dados
            </span>
            <span
              class="d-flex justify-content-center px-2 mb-1"
            >
              <b-spinner
                class="my-2"
                style="width: 5rem; height: 5rem; color: #28C76F;"
                label="Busy"
              />
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalEffectSuccess"
      ref="modalEffectSuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalSuccess"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiContentSaveCheckOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Modelo Exportado com Sucesso!
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <BButton
                id="hide-btn"
                variant="primary"
                style="min-width: 112px;"
                @click="closeModalEffectSuccess()"
              >
                Ok
              </BButton>
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalEffectUnsuccess"
      ref="modalEffectUnsuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalUnsuccess"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiContentSaveAlertOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Falha ao exportar dados para o modelo!
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <BButton
                id="hide-btn"
                variant="primary"
                style="min-width: 112px;"
                @click="closeModalEffectUnsuccess()"
              >
                Ok
              </BButton>
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalHomologate"
      ref="modalHomologate"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Deseja homologar essa licitação para o andamento do pedido?
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <BButton
                id="hide-btn"
                variant="primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="canHomologateBidding()"
              >
                Sim
              </BButton>
              <BButton
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeModalHomologate()"
              >
                Não
              </BButton>
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="confirmDeleteBidModal"
      ref="confirmDeleteBidModal"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center fixLayoutTitleModal"
            >
              Atenção!
            </span>
            <span
              class="d-flex justify-content-center my-1 fixLayoutTextModal"
            >
              Você realmente quer remover essa licitação? <br> Essa ação NÃO pode ser desfeita!
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <BButton
                id="hide-btn"
                variant="primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="removeBid()"
              >
                Sim
              </BButton>
              <BButton
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeConfirmDeleteBidModal()"
              >
                Não
              </BButton>
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalUndoHomologate"
      ref="modalUndoHomologate"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Essa licitação está fechada em homologação, deseja reabrir?
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <BButton
                id="hide-btn"
                variant="primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="canUndoHomologateBidding()"
              >
                Sim
              </BButton>
              <BButton
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeModalUndoHomologate()"
              >
                Não
              </BButton>
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalHomologateRejected"
      ref="modalHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1 py-2"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center fixLayoutTitleModal"
            >
              Não é possível homologar a licitação
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Um ou mais itens não estão marcados com status de ganho ou perdido.
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalLostHomologateRejected"
      ref="modalLostHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1 py-2"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextModalAlert"
            >
              A licitação não pode ser homologada com todos os produtos marcados como perdido.
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalNoItemHomologateRejected"
      ref="modalNoItemHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1 py-2"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextModalAlert"
            >
              Esta licitação não possui itens cadastrados, por esta razão não poderá ser homologada.
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalUndoHomologateRejected"
      ref="modalUndoHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1 py-2"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextModalAlert"
            >
              Não é possível realizar esta ação, a licitação está vinculada a um contrato.
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalHomologateSuccess"
      ref="modalHomologateSuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1 py-2"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalSuccess"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiCheckDecagram"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Licitação homologada com sucesso!
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalUndoHomologateSuccess"
      ref="modalUndoHomologateSuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <BForm
        class="mt-1 py-2"
      >
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalSuccess"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiCheckCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Licitação reaberta com sucesso!
            </span>
          </BCol>
        </BRow>
      </BForm>
    </BModal>
    <BModal
      id="idModalLoadingClassificationGrid"
      ref="modalLoadingClassificationGrid"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-header
      hide-footer
      size="lg"
    >
      <div v-if="loadingGrid === 'LOADING'">
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center pt-4 pb-2"
            >
              <b-spinner
                id="spinnerLoading"
                variant="primary"
                type="grow"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextLoadingGridTitle"
            >
              Buscando os dados da licitação no {{ platformName }} ...
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextLoadingGrid"
            >
              Esta operação pode demorar alguns minutos.
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextLoadingGrid"
            >
              Fique à vontade para navegar no sistema enquanto carregamos os dados da licitação!
            </span>
          </BCol>
        </BRow>
        <BRow class="mt-2 mb-3">
          <BCol class="d-flex justify-content-center">
            <b-button
              class="btn-icon mx-50"
              variant="primary"
              size="md"
              @click="continueBrowsing()"
            >
              <span class="d-inline">Continuar navegando</span>
            </b-button>
          </BCol>
        </BRow>
      </div>

      <div v-else-if="loadingGrid === 'NOT_FOUND'">
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 pt-3 mb-1 layoutModalAlert"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-2 layoutTextLoadingGrid"
            >
              Não foi possível localizar a licitação no {{ platformName }}, verifique os dados e tente novamente.
            </span>
          </BCol>
        </BRow>
        <BRow class="mb-3">
          <BCol class="d-flex justify-content-center">
            <b-button
              class="btn-icon mx-50"
              variant="primary"
              size="md"
              @click="fetchBiddingGrid(bidding.platform_set.name, biddingDataFetch, true)"
            >
              <span class="p-1">Tentar novamente</span>
            </b-button>

            <b-button
              class="btn-icon mx-50"
              variant="outline-primary"
              size="md"
              @click="backToBid()"
            >
              <span class="d-inline px-3">Voltar</span>
            </b-button>
          </BCol>
        </BRow>
      </div>

      <div v-else-if="loadingGrid === 'FAIL'">
        <BRow>
          <BCol>
            <span
              class="d-flex justify-content-center px-2 pt-3 mb-1 layoutModalAlertError"
            >
              <SvgIcon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-2 py-1 layoutTextLoadingGrid"
            >
              Erro ao carregar a licitação do {{ platformName }}!
            </span>
          </BCol>
        </BRow>
        <BRow class="mb-3">
          <BCol class="d-flex justify-content-center">
            <b-button
              class="btn-icon mx-50"
              variant="primary"
              size="md"
              @click="fetchBiddingGrid(bidding.platform_set.name, biddingDataFetch, true)"
            >
              <span class="p-1">Tentar novamente</span>
            </b-button>

            <b-button
              class="btn-icon mx-50"
              variant="outline-primary"
              size="md"
              @click="backToBid()"
            >
              <span class="px-3">Voltar</span>
            </b-button>
          </BCol>
        </BRow>
      </div>
    </BModal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BCardTitle,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
  BModal,
  BSpinner,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiClipboardTextOutline,
  mdiPaperclip,
  mdiFileDocumentPlusOutline,
  mdiPackageVariant,
  mdiClipboardTextClockOutline,
  mdiChevronRight,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiAlertCircle,
  mdiCheckCircle,
  mdiCheckDecagram,
  mdiFileExportOutline,
  mdiFileCertificateOutline,
  mdiCommentQuestionOutline,
  mdiFolderOpenOutline,
  mdiContentSaveCheckOutline,
  mdiContentSaveAlertOutline,
  mdiTrashCanOutline,
  mdiClipboardEditOutline,
  mdiPodium,
} from '@mdi/js'

import exportFromJSON from 'export-from-json'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/pt-br'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BiddingItems from '@/views/bidding/classification-grid/BiddingItems.vue'
import Messager from '@/components/messager/Messager.vue'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import truncate from '@/components/utils/filters/truncate'
import SyncLoad from '@/components/utils/syncLoad'
import syslic from '@/syslic'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BCardTitle,
    BButton,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal,
    BSpinner,
    SvgIcon,
    SpinLoader,
    Messager,
    BiddingItems,
  },
  filters: {
    truncate,
  },
  data() {
    let biddingId = ''

    const platformName = '-'

    const loadingGrid = 'LOADING'

    const biddingDataFetch = {}

    const bidding = {
      additional_address: '',
      additional_address_type: '',
      additional_address_type_set: {
        name: '',
      },
      additional_city: '',
      additional_city_set: {
        name: '',
      },
      additional_complement: '',
      additional_country: '',
      additional_country_set: {
        name: '',
      },
      additional_neighborhood: '',
      additional_neighborhood_type: '',
      additional_neighborhood_type_set: {
        name: '',
      },
      additional_number: '',
      additional_state: '',
      additional_state_set: {
        name: '',
      },
      additional_zip_code: '',
      client: '',
      client_set: {
        name: '',
      },
      company: '',
      company_set: '',
      crier: '',
      type: '',
      type_set: '',
      modality: '',
      modality_set: '',
      interest: '',
      interest_set: '',
      platform: '',
      platform_set: '',
      status: '',
      status_set: '',
      owner: '',
      owner_set: '',
      trade_number: '',
      uasg: '',
      link_trade: '',
      link_support: '',
      link_pncp: '',
      date: null,
      freight_group: '',
      freight_group_set: '',
      dispute: '',
      dispute_set: '',
      items_set: [],
      file_set: [],
      city: '',
      city_set: '',
      state: '',
      state_set: '',
      country: 1,
      country_set: '',
      proposal_validity: '',
      phone_number: '',
      email: '',
      exclusive_me_epp: false,
      price_registry: false,
      observation: '',
      object_bidding: '',
      requirements: [],
    }

    const grid = {
      items: [],
      query_date: '',
    }

    let activeTab = 1

    biddingId = router.currentRoute.params.id
    activeTab = router.currentRoute.params.tab

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('messager')
    syncLoad.addTrigger('bidding')
    syncLoad.addTrigger('classification-grid')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      biddingId,
      platformName,
      loadingGrid,
      biddingDataFetch,
      isLoaded,
      syncLoad,
      bidding,
      grid,
      activeTab,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_delete
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    normalizeString(str) {
      return str.normalize('NFD')
        .toLowerCase()
        .replace(/[\u0300-\u036f]/g, '')
        .replace(',', '')
        .replace('.', '')
        .replace('-', '')
        .replace('ltda', '')
        .trim()
    },
    fetchBiddingGrid(platform, biddingData, forceRefresh) {
      this.$refs.modalLoadingClassificationGrid.show()
      this.platformName = this.bidding.platform_set.name

      syslic
        .guru
        .fetchBiddingGrid(platform, biddingData, forceRefresh)
        .then(response => {
          this.grid = response

          const ownCompany = this.normalizeString(this.bidding.company_set.corporate_name)

          this.grid.items.forEach(item => {
            let position = '-'

            if (item.grid.length > 0) {
              item.grid.forEach((company, index) => {
                const companyName = this.normalizeString(company.name)

                if (companyName === ownCompany) {
                  position = index + 1
                }
              })
            }

            // eslint-disable-next-line no-param-reassign
            item.position = position
          })

          this.loadingGrid = response.status
          if (response.status === 'OK') {
            this.$refs.modalLoadingClassificationGrid.hide()
          } else if (response.status === 'LOADING') {
            setTimeout(() => {
              this.fetchBiddingGrid(platform, biddingData, false)
            }, 30000)
          }
          this.syncLoad.ready('classification-grid')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar a grade de classificação.',
              text: 'Não foi possível carregar a grade de classificação, por favor entre em contato com o administrador do sistema.',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          router.push({ name: 'bidding-view', params: { id: this.biddingId } })
          this.syncLoad.ready('classification-grid')
        })
    },
    fetchData() {
      syslic
        .bidding
        .fetchBidding(this.biddingId)
        .then(response => {
          this.bidding = response.data

          if (this.isPlatformSupported(this.bidding)) {
            const platform = this.bidding.platform_set.name
            this.biddingDataFetch = {
              trade_number: this.bidding.trade_number,
              uasg: this.bidding.uasg,
              date_proposal: this.bidding.date_proposal,
              city: this.bidding.city_set ? this.bidding.city_set.name : null,
              state: this.bidding.state_set ? this.bidding.state_set.code : null,
              link_trade: this.bidding.link_trade,
              link_support: this.bidding.link_support,
              link_pncp: this.bidding.link_pncp,
              modality: this.bidding.modality_set ? this.bidding.modality_set.name : null,
            }

            this.fetchBiddingGrid(platform, this.biddingDataFetch, false)
          } else {
            this.$router.push({ name: 'error-404' })
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar licitação.',
              text: 'Não foi possível carregar a licitação, por favor entre em contato com o administrador do sistema.',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })

          this.$router.push({ name: 'bidding-view', params: { id: this.biddingId } })
        })
        .finally(() => {
          this.syncLoad.ready('bidding')
        })
    },
    isPlatformSupported(bidding) {
      const SUPPORTED_PLATFORMS = ['BNC', 'BLL', 'COMPRASNET', 'LICITAÇÕES-E']
      const platform = bidding.platform_set.name.toUpperCase()

      return SUPPORTED_PLATFORMS.includes(platform)
    },
    delete() {
      syslic
        .bidding
        .deleteBidding(this.biddingId)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Licitação removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'bidding' })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover licitação.',
              text: 'Não foi possível remover a licitação do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    removeBid() {
      this.delete()
    },
    showModaEffect() {
      this.$refs.modalEffect.show()
    },
    closeModalEffectUnsuccess() {
      this.$refs.modalEffectUnsuccess.hide()
    },
    closeModalEffectSuccess() {
      this.$refs.modalEffectSuccess.hide()
    },
    exportProductsCSV() {
      this.$refs.modalEffectSpinner.show()

      syslic
        .bidding
        .fetchBidding(this.bidding.id)
        .then(response => {
          const dataBidding = response.data
          const dataExport = []

          const filteredItems = dataBidding.items_set.filter(item => item.type !== 'lote')

          filteredItems.forEach(element => {
            const itemEffect = {
              Item: element.number,
              Nome: element.name,
              'Descrição do Edital': element.description,
              Marca: '',
              Fabricante: '',
              Modelo: '',
              Unidade: '',
              QT: element.quantity,
              'Valor Minimo Unitário': element.price_min,
              'Valor Estimado Unitário': element.price,
              Total: this.formatCurrency(element.quantity * element.price),
              Lote: '',
            }

            if (element.product_list[0] && element.product_list[0].product_set) {
              const product = element.product_list[0].product_set
              if (product.brand) itemEffect.Marca = product.brand
              if (product.supplier_set && product.supplier_set.name) itemEffect.Fabricante = product.supplier_set.name
              if (product.model) itemEffect.Modelo = product.model
              if (product.unity_set && product.unity_set.symbol) itemEffect.Unidade = product.unity_set.symbol
            }

            if (element.parent) {
              itemEffect.Lote = element.parent_set.name
            }

            dataExport.push(itemEffect)
          })

          const fileName = 'exportação_effect'
          const exportType = exportFromJSON.types.csv
          exportFromJSON({ data: dataExport, fileName, exportType })

          setTimeout(() => {
            this.$refs.modalEffectSpinner.hide()
            this.$refs.modalEffectSuccess.show()
          }, 1000)
        })
        .catch(() => {
          setTimeout(() => {
            this.$refs.modalEffectSpinner.hide()
            this.$refs.modalEffectUnsuccess.show()
          }, 1000)

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao exportar dados da licitação.',
              text: '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    makeHomogation(isHomologating, value) {
      syslic
        .bidding
        .homologation
        .updateHomologation(this.bidding.id, value)
        .then(() => {
          if (isHomologating) {
            this.closeModalHomologate()
            this.$refs.modalHomologateSuccess.show()
          } else {
            this.closeModalUndoHomologate()
            this.$refs.modalUndoHomologateSuccess.show()
          }
          this.fetchBidding()
          this.$refs.refBiddingProductList.refreshAfterHomologate()
        })
        .catch(() => {
          if (isHomologating) {
            this.closeModalHomologate()
            const textError = 'Não foi possível homologar a licitação, por favor entre em contato com o administrador do sistema.'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao homologar a licitação.',
                text: textError,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          } else {
            this.closeModalUndoHomologate()
            const textError = 'Não foi possível desfazer a homologação da licitação, por favor entre em contato com o administrador do sistema.'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao desfazer a homologação da licitação.',
                text: textError,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          }
          this.fetchBidding()
        })
    },
    canHomologateBidding() {
      const items = this.bidding.items_set
      let haveItemPedding = false
      let isAllLost = true

      if (items.length > 0) {
        items.forEach(element => {
          if (!element.parent_set) {
            if (element.result === 'pending') {
              haveItemPedding = true
            }
            if (element.result !== 'lost') {
              isAllLost = false
            }
          }
        })

        if (!haveItemPedding && !isAllLost) {
          this.makeHomogation(true, true)
        } else {
          this.closeModalHomologate()

          if (haveItemPedding) {
            this.$refs.modalHomologateRejected.show()
          } else {
            this.$refs.modalLostHomologateRejected.show()
          }
        }
      } else {
        this.closeModalHomologate()
        this.$refs.modalNoItemHomologateRejected.show()
      }
    },
    canUndoHomologateBidding() {
      if (this.bidding.contract.length > 0) {
        this.closeModalUndoHomologate()
        this.$refs.modalUndoHomologateRejected.show()
      } else {
        this.makeHomogation(false, false)
      }
    },
    showModalHomologation() {
      this.$refs.modalHomologate.show()
    },
    closeModalHomologate() {
      this.$refs.modalHomologate.hide()
    },
    showConfirmDeleteBidModal() {
      this.$refs.confirmDeleteBidModal.show()
    },
    closeConfirmDeleteBidModal() {
      this.$refs.confirmDeleteBidModal.hide()
    },
    showModalUndoHomologation() {
      this.$refs.modalUndoHomologate.show()
    },
    closeModalUndoHomologate() {
      this.$refs.modalUndoHomologate.hide()
    },
    formatCurrency(value) {
      return value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    backToBid() {
      router.push({ name: 'bidding-edit', params: { id: this.bidding.id } })
    },
    continueBrowsing() {
      window.open(this.$router.resolve({ name: 'bidding-edit', params: { id: this.bidding.id } }).href, '_blank')
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiClipboardTextOutline,
      mdiPaperclip,
      mdiFileDocumentPlusOutline,
      mdiPackageVariant,
      mdiClipboardTextClockOutline,
      mdiChevronRight,
      mdiFileDocumentOutline,
      mdiPlus,
      mdiAlertCircle,
      mdiCheckCircle,
      mdiCheckDecagram,
      mdiFileExportOutline,
      mdiFileCertificateOutline,
      mdiCommentQuestionOutline,
      mdiFolderOpenOutline,
      mdiContentSaveCheckOutline,
      mdiContentSaveAlertOutline,
      mdiTrashCanOutline,
      mdiClipboardEditOutline,
      mdiPodium,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idActions {
  button {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.uasgTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.infoStatus {
  min-width: 185px;
}

#editPage {
  transform: none !important;
}

.margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.card-title {
  color: $primary
}

#biddingHeaderTitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

#biddingLinkTrade {
  color: #9F9DA8;
  font-weight: 500;
}

.biddingHeaderBlock {
  padding-top: 0.20rem;
  padding-bottom: 0.20rem;
}

.biddingHeaderBlock .media{
  margin-bottom: 0.5rem;
}

.biddingHeaderBlock .media-body{
  color: #5e5873;
  margin-top: 0.5rem;
}

.biddingHeaderBlock.left {
  border-left: 1px solid #ebe9f1;
}

.biddingHeaderSectionTitle {
  font-weight: 700;
  font-size: 18px;
  margin-top: 0.75rem;
}

.colorIconAlert {
  color: #FF7A00;
}

#idModalEffect .vc-slider {
  width: 100% !important;
}

#idModalEffect .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: center;

  .btn{
    min-width: 107px;
  }
}

.modalStyle .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;

  justify-content: center;

  padding-bottom: 40px;
}

#btnExportEffect {
  border: none !important;
  span {
    color: #9F9DA8;
  }
}

#btnUndoHomologation {
  background-color: $success_2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  #icon-btn-homologated {
    background-color: white;
    border-radius: 100px;
    padding: 0.3em;

    path {
      color: $success_2;
    }
  }
}

#btnUndoHomologation:hover {
  #icon-btn-homologated path {
    color: $gray-900;
  }
}

.fixLayoutTextModal {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.fixLayoutTitleModal {
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
}

.layoutModalSuccess {
  text-align: center;
  svg {
    color: $success_2;
  }
}

.layoutModalUnsuccess {
  text-align: center;
  svg {
    color: #ea5455;
  }
}

.layoutModalAlert {
  text-align: center;
  svg {
    color: #FF7A00;
  }
}

.layoutModalAlertError {
  text-align: center;
  svg {
    color: #FF0000;
  }
}

.layoutTextModalAlert {
  text-align: center;
  font-weight: 500;
}

.titleCard {
  color: #049F14;
}

#lastUpdate {
  color: var(--1-theme-color-body-text, #6E6B7B);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 13.884px; /* 150% */
}

#spinnerLoading {
  width: 3rem;
  height: 3rem;
}

.layoutTextLoadingGrid {
  color: #464646;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 17.415px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.layoutTextLoadingGridTitle {
  color: #464646;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
